// @import url("@tabler/core/dist/css/tabler.min.css");
@import "./config";
@import "./dashboard";
@import "./auth.scss";
@import "./loader";
// @import "../components/pagination/pagination.scss";
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}
