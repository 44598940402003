.signup {
    //   .card-contain {
    //     position: relative;
    //   }
    @include rwd(
        min-height,
        100vh,
        (
            $md: 100vh,
            $lg: 130vh,
            $xl: 100vh,
            $xxl: 100vh,
        )
    );
    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
    }
    .auth-card {
        margin: 0;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // -ms-transform: translate(-50%, -50%);
        // transform: translate(-50%, -50%);
        border-radius: 0px;
        background-color: #fff;
        box-shadow: 0px 15px 50px 0px #3974de26;
        border-radius: 10px;
        @include rwd(
            width,
            90%,
            (
                $md: 50%,
                $lg: 40%,
                $xl: 30%,
                $xxl: 27%,
            )
        );
        & .input-error {
            border: 1px solid red;
        }
        & .error {
            color: red !important;
        }
        & input {
            &:focus {
                outline-width: 0;
                border: 1px solid $primary-color;
            }
            border: 1px solid #b4b4b4;
            border-radius: 5px;
            @include rwd(
                width,
                90%,
                (
                    $md: 80%,
                    $lg: 80%,
                    $xl: 75%,
                    $xxl: 70%,
                )
            );
            &.password,
            &.confirm-password {
                margin-left: -20px;
                @include rwd(
                    width,
                    90%,
                    (
                        $md: 80%,
                        $lg: 80%,
                        $xl: 75%,
                        $xxl: 70%,
                    )
                );
            }
            &__input-error {
                border: 1px solid red !important;
            }
            &--error-text {
                color: red !important;
            }
            //   margin: auto;
        }
    }
    & .top-label {
        background-color: $primary-color;
        // box-shadow: 0px 15px 50px 0px #3974de26;
        border-radius: 10px 10px 0px 0px;
    }
    & h5 {
        font-weight: 700;
        color: #222222;
    }
    & button,
    & a {
        @include rwd(
            width,
            90%,
            (
                $md: 80%,
                $lg: 80%,
                $xl: 75%, 
                $xxl: 70%,
            )
        );
        margin: auto;
    }
    & i {
        margin-left: -40px;
        color: $primary-color;
        padding: 0;
    }
    & .password-group {
        margin-left: -10px;
        @include rwd(
            width,
            100%,
            (
                $md: 100%,
                $lg: 100%,
                $xl: 100%,
                $xxl: 100%,
            )
        );
    }
    & .google-icon {
        @include rwd(
            width,
            9%,
            (
                $md: 9%,
                $lg: 8%,
                $xl: 7%,
                $xxl: 7%,
            )
        );
    }
}
