$primary-color: #001149;

$mid-color: #446403;
$trans-color: #76a7134f;
$secondary-color: #ff9b04;
$second-color: #bd7303;
$bowen-blue-bg: #151948;
$bowen-blue: #0083c9;
$bowen-red: #d9261f;
$bowen-green: #3ba658;
$bowen-yellow: #fac300;

$md: 768px;
$lg: 1024px;
$xl: 1440px;
$xxl: 1920px;

@mixin rwd($propertyName, $defaultValue, $mediaQueries: ()) {
  #{$propertyName}: #{$defaultValue} !important;

  @each $breakpoint, $value in $mediaQueries {
    @media (min-width: #{$breakpoint}) {
      #{$propertyName}: #{$value} !important;
    }
  }
}
.link {
  background-color: $primary-color !important;
  border-radius: 5px;
  border: 3px solid $primary-color !important;
  color: #fff !important;
  z-index: 1;
  transition: ease-in-out 300ms;
  box-shadow: 0px 4px 10px rgba(51, 51, 51, 0.1);
  &:hover {
    background-color: $bowen-blue !important;
    color: #fff;
    border: 3px solid $bowen-blue !important;
    // box-shadow: 0px 15px 50px 0px #b6b6b64d;
    // margin-top: -2px;
  }
}
.link__two {
  background-color: $secondary-color !important;
  border-radius: 5px;
  border: 3px solid $secondary-color !important;
  color: #fff !important;
  z-index: 1;
  transition: ease-in-out 300ms;
  box-shadow: 0px 4px 10px rgba(51, 51, 51, 0.1);
  &:hover {
    background-color: $second-color !important;
    color: #fff;
    border: 3px solid $second-color !important;
    // box-shadow: 0px 15px 50px 0px #b6b6b64d;
    // margin-top: -2px;
  }
}
h1 {
  @include rwd(
    font-size,
    2.7rem,
    (
      $md: 3.2rem,
      $lg: 3.6rem,
      $xl: 4rem,
      $xxl: 4.2rem,
    )
  );
  font-weight: 700;

  &.hero-header-title {
    color: #fff;
  }
}

h2 {
  @include rwd(
    font-size,
    2.1rem,
    (
      $md: 2.3rem,
      $lg: 2.6rem,
      $xl: 3rem,
      $xxl: 3.3rem,
    )
  );
  font-weight: 800;
  &.play-font {
    font-family: "Great Vibes", cursive;
  }
}

h4 {
  @include rwd(
    font-size,
    1.7rem,
    (
      $md: 2.1rem,
      $lg: 2.5rem,
      $xl: 2.8rem,
      $xxl: 3rem,
    )
  );
  font-weight: 700;
  // color: $secondary-color;
  span.play-font {
    font-family: "Great Vibes", cursive;
  }
  margin: auto;
}

h6 {
  @include rwd(
    font-size,
    1.1rem,
    (
      $md: 1.1rem,
      $lg: 1.2rem,
      $xl: 1.3rem,
      $xxl: 1.4rem,
    )
  );
  font-weight: 600;
  // color: $secondary-color;
}

h5 {
  @include rwd(
    font-size,
    1.2rem,
    (
      $md: 1.3rem,
      $lg: 1.4rem,
      $xl: 1.6rem,
      $xxl: 1.8rem,
    )
  );
  font-weight: 700;
  // color: $secondary-color;
}

p {
  @include rwd(
    font-size,
    1rem,
    (
      $md: 1rem,
      $lg: 1rem,
      $xl: 1rem,
      $xxl: 1rem,
    )
  );
  @include rwd(
    line-height,
    23px,
    (
      $md: 25px,
      $lg: 27px,
      $xl: 29px,
      $xxl: 30px,
    )
  );
  font-weight: 400;
}

// FONT AWESOME ICONS

.custom .fa-circle:before {
  border-radius: 50%;
  border: 2px solid $trans-color;
  background: $trans-color;
  color: $trans-color;
  cursor: pointer;
}
.custom-b .fa-circle:before {
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.39);
  background: $primary-color;
  color: transparent;
}
.custom-c .fa-circle:before {
  border-radius: 50%;
  // border: 2px solid transparent;
  background: $trans-color;
  color: transparent;
  cursor: pointer;
}
// .custom.fa-stack:hover .fa-circle:before {
//   background: $primary-color !important;
// }
.custom-c.fa-stack:hover .fa-circle:before {
  background: $primary-color !important;
}
// .custom-c .fa-pen-to-square,
// .custom-c .fa-trash {
//   color: red;
//   &:hover {
//     color: #fff;
//   }
// }

.custom-c {
  &--icon {
    color: $primary-color !important;
    &:hover {
      color: #fff !important;
    }
  }
}
.fa-stack:hover .fa-circle:before {
  background: $trans-color !important;
  border: 2px solid $trans-color;
}
.custom.fa-stack:hover .fa-circle:before {
  background: transparent !important;
  color: transparent !important;
  border: 2px solid $trans-color;
}

// FONT AWESOME ICONS
