body {
  margin: 0;
  font-family: "Lato", sans-serif;
}
.input-error {
  border: 1px solid red !important;
}
.error {
  color: red !important;
}
.disable-link {
  pointer-events: none;
}

.sidebar {
  margin: 0;
  padding: 0;
  width: 280px;
  background-color: #f1f1f1;
  position: fixed;
  height: 90%;
  margin-top: 2.5%;
  overflow: auto;
  border-radius: 50px;
  // font-weight: 500;
  &__link {
    display: block;
    // color: #3f3f3f !important;
    padding: 16px;
    text-decoration: none;
    border-radius: 5px;
    font-weight: 500;
    font-size: 0.9rem;
    @include rwd(
      width,
      auto,
      (
        $md: auto,
        $lg: 100%,
        $xl: 100%,
        $xxl: 100%,
      )
    );
  }
  & a.active {
    background-color: $primary-color;
    color: $primary-color;
    font-weight: 600;
    color: #fff !important;
  }
  &__logo {
    border-bottom: 1px solid #adadad;
    &--img {
      width: 4rem;
    }
  }
  &__logout {
    bottom: 10px;
    left: 60px;
    margin-top: auto;
    position: absolute;
    &--btn {
      border: 0 !important;
      &:hover {
        color: $secondary-color !important;
        border: 0px !important;
        background-color: transparent !important;
      }
    }
    // border-top: 1px solid #adadad;
  }
}

.sidebar a {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
}

.sidebar a.active {
  background-color: $primary-color;
  color: white;
}

.sidebar a:hover:not(.active) {
  background-color: $primary-color;
  color: white;
}

div.content {
  margin-left: 330px;
  padding: 1px 16px;
  // height: 1000px;
}

@media screen and (max-width: 1024px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
    margin-top: 0;
    &__logout {
      bottom: 10px;
      left: 10%;
      margin-top: auto;
      position: static;
    }
  }

  .sidebar a {
    float: left;
  }
  div.content {
    margin-left: 0;
  }
}

@media screen and (max-width: 425px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}
.dashboard {
  width: 95%;
}
.main {
  background-color: #f1f1f1;
  min-height: 100vh;
  &__container {
    width: 95%;
    margin: auto;
  }
}
.page-wrapper {
  @include rwd(
    margin-left,
    0,
    (
      $md: 0,
      $lg: 250px,
      $xl: 250px,
      $xxl: 0px,
    )
  );
}
.content {
  &--previousbtn {
    background: none;
    border: 0;
    &:hover {
      color: $secondary-color;
    }
  }
  @include rwd(
    margin-left,
    0,
    (
      $md: 0,
      $lg: 300px,
      $xl: 310px,
      $xxl: 320px,
    )
  );
  color: #202020;
  // margin-top: 5%;
  background-color: #ffffff;
  &--subtext {
    font-size: 1.3rem !important;
    font-weight: 400;
    color: #5c5c5c;
  }
  &--headtext {
    font-weight: 700;
    color: #464646;
    font-size: 1rem !important;
  }
  &__header {
    &--left {
      @include rwd(
        margin-bottom,
        30px,
        (
          $md: 0,
          $lg: 0,
          $xl: 0,
          $xxl: 0,
        )
      );
    }
    &--right {
      @include rwd(
        margin-bottom,
        15px,
        (
          $md: 0,
          $lg: 0,
          $xl: 0,
          $xxl: 0,
        )
      );
    }
  }
  &__form {
    & .input-error {
      border: 1px solid red;
    }
    & .error {
      color: red !important;
    }
    &--btn {
      @include rwd(
        width,
        100%,
        (
          $md: 50%,
          $lg: 40%,
          $xl: 35%,
          $xxl: 30%,
        )
      );
    }
    &--input {
      border-radius: 5px;
      border: 1px solid #828282;
      @include rwd(
        width,
        100%,
        (
          $md: 100%,
          $lg: 80%,
          $xl: 80%,
          $xxl: 75%,
        )
      );
      &:focus {
        outline-color: $primary-color;
        outline-width: 1px;
        // border: 1px solid $primary-color;
      }
    }
  }
  &__card {
    background: #ffffff;
    box-shadow: 0px 5px 15px rgba(122, 122, 122, 0.15);
    border-radius: 10px;
    overflow-x: auto !important;
    &::-webkit-scrollbar {
      @include rwd(
        height,
        3px,
        (
          $md: 5px,
          $lg: 7px,
          $xl: 7px,
          $xxl: 7px,
        )
      );
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px #828282;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bdbdbd;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: $primary-color;
    }
    & label {
      width: 100%;
    }
    &--input {
      border: 0.5px solid #e0e0e0;
      border-radius: 5px;
      width: 400px;
      &:focus {
        border: 0.5px solid $primary-color;
      }
    }
    &--icon {
      margin-left: -40px;
      color: $primary-color;
      cursor: pointer;
    }
    &--row {
      width: 100%;
      &:hover {
        cursor: pointer;
        background-color: rgba(255, 204, 3, 0.3);
      }
    }
    &--active {
      background: #ffcc03;
      border-radius: 5px;
    }
    &--expired {
      background: #e0e0e0;
      border-radius: 5px;
    }
  }
}

// DASHBOARD STARTS
.dashnav {
  width: 95%;
  margin: auto;
  background-color: #fff;
  position: relative;
  &__navi {
    background-color: rgba(255, 255, 255, 0);
    width: 100%;
    margin: auto;
  }
  &__container {
    width: 95%;
    margin: auto;
  }
  &__logout {
    border: 0;
    background-color: #ffffff !important;
    &:hover {
      color: $primary-color;
      // background-color: #ffffff !important;
      cursor: pointer;
      border: 0;
    }
    &--icon {
      color: $primary-color;
    }
  }
  &__menu--btn {
    background-color: #1d273b !important;
    color: #fff !important;
  }
}
.search {
  &--input {
    border-radius: 5px;
    border: 1px solid #afafaf;
    width: 150px;
    transition: ease-out 0.3s;
    &:focus {
      // border: 0.5px solid $primary-color !important;
      outline-width: 0.5px !important;
      outline-color: $bowen-blue;
      width: 50%;
      // transition: ease-out 0.4s;
    }
  }
  & i {
    margin-left: -30px;
    color: $primary-color;
    padding: 0;
  }
}
.modal-body {
  background: #fff !important;
}
.lecturer__lead {
  color: $bowen-blue;
  &--label {
    background-color: $bowen-blue;
    color: $bowen-blue-bg;
    border-radius: 10px 0;
    color: #fff;
  }
}
// DASHBOARD ENDS
